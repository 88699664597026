import {Badge, Tab, Tabs} from "react-bootstrap";

export default function Step6ConfigureApps() {
    return (
        <>
            <Tabs justify className="mt-3">
                <Tab eventKey="6-1" title={<><Badge className="bg-secondary">6.1</Badge><p>Install Base Terminal Server Applications</p></>}>
                    Step 6.1 goes here
                </Tab>
                <Tab eventKey="6-2" title={<><Badge className="bg-secondary">6.2</Badge><p>Install Ancillary Server Applications</p></>}>
                    Step 6.2 goes here
                </Tab>
                <Tab eventKey="6-3" title={<><Badge className="bg-secondary">6.3</Badge><p>Create Base Terminal Image</p></>}>
                    Step 6.3 goes here
                </Tab>
            </Tabs>
        </>
    );
}