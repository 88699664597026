import Utils from "../utils";
import Graphql from "../graphql";

class Queries {
    static async sftpCredentials(sftpCredentialSecret, migrationRegion) {
        const username = await Utils.getSecret('Username', sftpCredentialSecret, migrationRegion).then((secret) => {
            return secret;
        });

        const password = await Utils.getSecret('Password', sftpCredentialSecret, migrationRegion).then((secret) => {
            return secret;
        });

        return [username, password];
    }

    static async getAssignableStack(clientId) {
        const fetchData = async () => {
            const listStacks = `
                query ListStacks($filter: ModelStackFilterInput, $limit: Int, $nextToken: String) {
                    listStacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                            stackName
                            az1
                            az1Cidr
                            az2
                            az2Cidr
                            _deleted
                            _version
                        }
                    }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    },
                    clientStacksClientId: {
                        eq: clientId
                    }
                }
            }

            return await Graphql.query(listStacks, variables).then((gq) => {
                return gq.listStacks.items;
            });
        };

        return await fetchData();
    }

    static async assignStack() {
        /*TODO (SPA-2165) - which mutation do we use here?*/
        const pushData      = async () => {
            return /*await Graphql.query())*/;
        }

        return /*pushData()*/;
    }

    static updateMigration(data) {
        const pushData = async () => {
            const updateMigration = `
                mutation UpdateMigration(
                    $input: UpdateMigrationInput!
                    $condition: ModelMigrationConditionInput
                  ) {
                    updateMigration(input: $input, condition: $condition) {
                      id
                      _version
                      _deleted
                      _lastChangedAt
                    }
                  }
            `;

            let variables = {
                input: data,
            }

            return await Graphql.query(updateMigration, variables);
        }

        return pushData();
    }

    static deleteMigration(migration) {
        const pushData = async() => {
            const deleteMigration = `
                mutation DeleteMigration(
                    $input: DeleteMigrationInput!
                    $condition: ModelMigrationConditionInput
                ) {
                    deleteMigration(input: $input, condition: $condition) {
                        id
                        migrationName
                    }
                }
            `;

            let variables = {
                input: {
                    id: migration.id,
                    _version: migration._version
                }
            }

            return await Graphql.query(deleteMigration, variables);
        }

        return pushData();
    }
}

export default Queries;