import {Badge, Tab, Tabs} from "react-bootstrap";
import Utils from "../../../../lib/utils";
import React, { useCallback } from "react";
import { useParams } from 'react-router-dom';
import ProductLaunch from "../../servicecatalog/ManageProduct";



export default function Step2ProvisionSftp(clientId,dateRegion) {

    const sftpUsername = `ngcstmr${clientId}`;
    const sftpConnectionString = `${sftpUsername}@${Utils.getSftpEndpointName(dateRegion)}`
    const startWinSCPCmd = `.\\start_winscp.bat ${sftpConnectionString}`

    
    
    const onDownload = async () => {
        try {
            await Utils.downloadFile(`lynx-frontend-${process.env.REACT_APP_LYNX_ENVIRONMENT}-assets`, 'sftp-client.zip');
        } catch (error) {
            console.error('Error in downloading object:', error);
        }
    };


    return (
        <>
            <Tabs justify className="mt-3">
                <Tab eventKey="2-1" title={<><Badge className="bg-secondary">2.1</Badge><p>Provision SFTP Using Service Catalog</p></>}>
                    <ProductLaunch step="sftp-provision" />
                </Tab>
                <Tab eventKey="2-2" title={<><Badge className="bg-secondary">2.2</Badge><p>Validate Credentials</p></>}>
                    <div className="step-content">
                        <div className="instructions">
                            <ol>
                                <li>
                                    Click the button on the right to download the latest SFTP Client package.
                                    <button 
                                        style={{
                                            alignSelf: 'center', 
                                            marginLeft: '10px', 
                                            height: '30px', 
                                            backgroundColor: '#007bff', 
                                            color: 'white', 
                                            border: 'none', 
                                            borderRadius: '4px', 
                                            cursor: 'pointer'
                                        }}
                                        onClick={onDownload}
                                    >
                                        sftp-client.zip
                                    </button>
                                </li>
                                <li>
                                    Unzip the package to a new folder on your desktop.
                                </li>
                                <li>
                                    Navigate to the new folder in explorer.
                                </li>
                                <li>
                                    While holding down the <b>Shift</b> key, right-click an empty area inside the folder and select <i>Open Powershell window here.</i>
                                </li>
                                <li>
                                    Execute this command to launch WinSCP:
                                    <pre style={{
                                        backgroundColor: '#f5f5f5', 
                                        padding: '10px', 
                                        borderRadius: '4px', 
                                        fontFamily: 'monospace'
                                    }}>
                                        {startWinSCPCmd}
                                    </pre>
                                </li>
                                <li>
                                    Click the "View SFTP Credentials" button at the top of this page
                                </li>
                                <li>
                                    Enter the username and password displayed under "SFTP Credentials".
                                </li>
                            </ol>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </>
    );
}