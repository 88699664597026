



export default function ProductLaunch({step}) {
    console.log('Received step:', step);
    const isUpdate = true; // just a placeholder for now
  
    const renderStepInstruction = (step) => {
      const clickAction = isUpdate ? "Update" : "Launch"; 
  
      switch (step) {
        case "sftp-provision":
          return (
            <ol>
              <li>Click the <b>{clickAction}</b> button below to begin provisioning an
                SFTP user and S3 bucket for the customer.</li>
              <li>Click <b>Confirm</b> in the displayed modal.</li>
              <li>Once Provisioning Status changes to <b>AVAILABLE</b>, you may move to the next
                step.</li>
            </ol>
          );
        case "launch-customer-stack":
          return (
            <ol>
              <li>Click the <b>{clickAction}</b> button below to confirm the provisioning parameters for the new customer Stack.</li>
              <li>Click <b>{clickAction}</b> in the displayed modal. If there are fields highlighted as missing, make sure that the Pod and Stack definitions
                in  Environment Manager targeted for migraiton contain all of the pre-requisite parameters.
              </li>
              <li>Once Provisioning Status changes to <b>AVAILABLE</b>, you may move to the next step.</li>
            </ol>
          );
        case "update-customer-stack":
          return (
            <ol>
              <li>Click the <b>{clickAction}</b> button below to update the provisioning parameters for the existing customer stack.</li>
              <li>Enter the AMI ID you want to use for the Terminal Server fleet. Include the desired number of terminal and test servers.</li>
            </ol>
          );
        default:
          return (
            <ol>
              <li>(Instructions are unavailable for this step)</li>
            </ol>
          );
      }
    };
  
    const renderTable = () => {
      return (
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th>Base Product</th>
              <th>Provisioned Product</th>
              <th>Provisioning Status</th>
              <th>Launched</th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Base Data</td> {/* this is a placeholder */}
              <td>Product Name</td> {/* this is a placeholder */}
              <td>Available</td> {/* this is a placeholder */}
              <td>2025-01-01</td> {/* this is a placeholder */}
              <td>2025-01-02</td> {/* this is a placeholder */}
              <td>
                <button className="btn btn-primary"style={{ marginRight: '10px' }}>{isUpdate ? 'Update' : 'Launch'}</button>
                <button className="btn btn-primary"style={{ marginRight: '10px' }}>Details</button>
                <button className="btn btn-primary"style={{ marginRight: '10px' }}>Refresh</button>
              </td>
            </tr>
          </tbody>
        </table>
      );
    };
  
    return (
      <div>
        {renderStepInstruction(step)}
        {renderTable()}
      </div>
    );
  };