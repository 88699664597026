import {Badge, Tab, Tabs} from "react-bootstrap";

export default function Step5DeployStack() {
    return (
        <>
            <Tabs justify className="mt-3">
                <Tab eventKey="5-1" title={<><Badge className="bg-secondary">5.1</Badge><p>Create Baseline Target Account</p></>}>
                    Step 5.1 goes here
                </Tab>
                <Tab eventKey="5-2" title={<><Badge className="bg-secondary">5.2</Badge><p>Launch Customer Stack</p></>}>
                    Step 5.2 goes here
                </Tab>
            </Tabs>
        </>
    );
}