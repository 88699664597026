import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Auth from "../../../lib/auth";
import Graphql from "../../../lib/graphql";
import Utils from "../../../lib/utils";

import { Accordion, Badge, Button, Tab, Tabs } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";

import Queries from "../../../lib/migration/queries";
import Modals from "../../../lib/migration/modals";

import Step1MigrationQuestionnaire from "./steps/step1MigrationQuestionnaire";
import Step2ProvisionSftp from "./steps/step2ProvisionSftp";
import Step3MigrateFileshare from "./steps/step3MigrateFileshare";
import Step4MigrateDatabase from "./steps/step4MigrateDatabases";
import Step5DeployStack from "./steps/step5DeployStack";
import Step6ConfigureApps from "./steps/step6ConfigureApps";
import Step7DeployFleet from "./steps/step7DeployFleet";
import Step8UpdateFileshare from "./steps/step8UpdateFileshare";
import Step9UpdateDatabases from "./steps/step9UpdateDatabases";

export default function MigrationDetail() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const { migrationId }   = useParams();
    const navigate          = useNavigate();

    const [migration, setMigration]     = useState({});
    const [client, setClient]           = useState({});
    const [targetStack, setTargetStack] = useState({});
    const [loading, setLoading]         = useState(true);

    const [showViewSftpCredentialsModal, setShowViewSftpCredentialsModal]   = useState(false);
    const [showSelectTargetStackModal, setShowSelectTargetStackModal]       = useState(false);
    const [showEditDetailsModal, setShowEditDetailsModal]                   = useState(false);
    const [showDeleteMigrationModal, setShowDeleteMigrationModal]           = useState(false);

    const [sftpUsername, setSftpUsername]   = useState(false);
    const [sftpPassword, setSftpPassword]   = useState(false);

    const [assignableStacks, setAssignableStacks] = useState([]);

    const handleCloseViewSftpCredentialsModal   = () => setShowViewSftpCredentialsModal(false);
    const handleOpenViewSftpCredentialsModal    = () => setShowViewSftpCredentialsModal(true);

    const handleCloseSelectTargetStackModal = () => setShowSelectTargetStackModal(false);
    const handleOpenSelectTargetStackModal  = () => setShowSelectTargetStackModal(true);

    const handleCloseEditDetailsModal   = () => setShowEditDetailsModal(false);
    const handleOpenEditDetailsModal    = () => setShowEditDetailsModal(true);

    const handleCloseDeleteMigrationModal   = () => setShowDeleteMigrationModal(false);
    const handleOpenDeleteMigrationModal    = () => setShowDeleteMigrationModal(true);

    useEffect(() => {
        const fetchData = async () => {
            const getMigration = `
                query GetMigration($id: ID!) {
                    getMigration(id: $id) {
                        id
                        dataMigrationRegion
                        migrationName
                        customerBaselineAMI {
                            name
                            location
                            createdAt
                            amiType
                            description
                            id
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            migrationModifiedCustomerBaselineAMIId
                            __typename
                        }
                        modifiedCustomerBaselineAMI {
                            nextToken
                            startedAt
                            __typename
                        }
                        scheduledStart
                        scheduledCutover
                        customerInventory {
                            nextToken
                            startedAt
                            __typename
                        }
                        client {
                            clientId
                            clientName
                            leveraged
                            multiTimezone
                            userCount
                            timeZone
                            purchasedStoraged
                            internalSubnets
                            osVersion
                            sqlDriveLetter
                            createdAt
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            __typename
                            stacks {
                                items {
                                    id
                                    stackName
                                    az1
                                    az2
                                    az1Cidr
                                    az2Cidr
                                    pod {
                                        podName
                                    }
                                }
                            }
                        }
                        terminalServers {
                            nextToken
                            startedAt
                            __typename
                        }
                        status
                        tasks {
                            nextToken
                            startedAt
                            __typename
                        }
                        useDms
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        clientMigrationsClientId
                        migrationCustomerBaselineAMIId
                        __typename
                    }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    }
                },
                id: migrationId
            }

            return await Graphql.query(getMigration, variables).then(async (gq) => {
                // TODO - why doesn't getMigration have filter options? filtering this after the query for now...
                if (gq.getMigration._deleted === true) {
                    return {};
                }

                setClient(gq.getMigration.client);
                setTargetStack(gq.getMigration.client.stacks);
                setMigration(gq.getMigration);

                return gq.getMigration;
            });
        };

        fetchData().then(async (migration) => {
            const sftpCredentialSecret  = `/nextgen/${process.env.REACT_APP_LYNX_ENVIRONMENT}/envmgr/sftp/ngcstmr${migration.client.clientId}`;
            let creds                   = await Queries.sftpCredentials(sftpCredentialSecret, Utils.formatRegion(migration.dataMigrationRegion));

            setSftpUsername(creds[0]);
            setSftpPassword(creds[1]);

            return migration;
        }).then( async (m) => {
            let stacks = await Queries.getAssignableStack(m.client.clientId);

            setAssignableStacks(stacks);
        }).then(() => {
            setLoading(false);
        });
    }, []);

    const sftpInternalEndpoint      = Utils.getInternalSftpEndpointName(migration.dataMigrationRegion);//`sftp-${process.env.REACT_APP_LYNX_ENVIRONMENT}-us-east-2.nextgenmcs.com`;
    const sftpExternalEndpoint      = Utils.getSftpEndpointName(migration.dataMigrationRegion);//`sftp-east2-internal.nextgenmcs.com`;

    const sftpInternalCodeString    = `.\\start_winscp.bat "ngcstmr${client.clientId}@${sftpInternalEndpoint}"`;
    const sftpExternalCodeString    = `.\\start_winscp.bat "ngcstmr${client.clientId}@${sftpExternalEndpoint}"`;

    function targetStackTable () {
        return (
            <table className="lynx-table table table-striped table-hover table-bordered">
                <thead>
                <tr>
                    <th>
                        Stack Name
                    </th>
                    <th>
                        Primary AZ
                    </th>
                    <th>
                        Primary CIDR
                    </th>
                    <th>
                        Secondary AZ
                    </th>
                    <th>
                        Secondary CIDR
                    </th>
                    <th>
                        POD Attached to Stack
                    </th>
                </tr>
                </thead>
                {targetStackTableRows(typeof targetStack.items !== "undefined" ? targetStack.items[0] : {})}
            </table>
        );
    }

    function targetStackTableRows(stack) {
        if (!stack) {
            return;
        }

        return (
            <tbody>
                <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/stacks/${stack.id}`)} key={stack.id}>
                    <td>{stack.stackName}</td>
                    <td>{stack.az1}</td>
                    <td>{stack.az1Cidr}</td>
                    <td>{stack.az2}</td>
                    <td>{stack.az2Cidr}</td>
                    <td>{stack.pod && stack.podName !== "undefined" ? stack.pod.podName : 'N/A'}</td>
                </tr>
            </tbody>
        );
    }

    function handleSubmitDeleteMigrationModal() {
        return Queries.deleteMigration(migration).then(() => navigate('/migrations'));
    }

    function handleSubmitEditDetailsModal() {
        const form          = document.getElementById("updateMigration");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formData.forEach((value, key) => formDataObj[key] = value);

        formDataObj.id                      = migration.id;
        formDataObj.dataMigrationRegion     = !formDataObj.dataMigrationRegion ? 'us_east_2' : formDataObj.dataMigrationRegion;
        formDataObj.status                  = !formDataObj.status ? 'NotStarted' : formDataObj.status;
        formDataObj.useDms                  = formDataObj.useDms === "on";
        formDataObj.scheduledStart          = !formDataObj.scheduledStart ? null : formDataObj.scheduledStart;
        formDataObj.scheduledCutover        = !formDataObj.scheduledCutover ? null : formDataObj.scheduledCutover;
        formDataObj._version                = migration._version

        if (!formDataObj.migrationName) {
            alert('Missing required fields');
            return false;
        }

        return Queries.updateMigration(formDataObj).then(() => window.location.reload());
    }

    return (
        <>
            <h1>
                Migration details {`${migration.migrationName ? '- ' + migration.migrationName : ''}`}
            </h1>

            <ScaleLoader loading={loading} />

            <hr />

            <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Migration Details</Accordion.Header>
                    <Accordion.Body>
                        <div className="container">
                            <div className="loading_wrapper">
                                <ScaleLoader loading={loading} />
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="container border border-light-subtle">
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Client ID
                                            </div>
                                            <div className="col-sm">
                                                {client.clientId}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Client Name
                                            </div>
                                            <div className="col-sm">
                                                {client.clientName}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Estimated Start
                                            </div>
                                            <div className="col-sm">
                                                {migration.scheduledStart}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Estimated Cutover
                                            </div>
                                            <div className="col-sm">
                                                {migration.scheduledCutover}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Customer Timezone
                                            </div>
                                            <div className="col-sm">
                                                {client.timeZone}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Data Migration Region
                                            </div>
                                            <div className="col-sm">
                                                {Utils.formatRegion(migration.dataMigrationRegion)}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Use Data Migration Region Service?
                                            </div>
                                            <div className="col-sm">
                                                {migration.useDms ? 'Yes' : 'No'}
                                            </div>
                                        </div>
                                        <div className="row border border-light-subtle">
                                            <div className="col fw-bold">
                                                Pod Client is Attached to
                                            </div>
                                            <div className="col-sm">
                                                {migration.client && (typeof migration.client.stacks !== "undefined") && (typeof migration.client.stacks.items[0] !== "undefined") ? migration.client.stacks.items[0].pod.podName : 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-5 mt-5">
                                    <div className="mw-100 clearfix">
                                        {targetStackTable()}
                                        <div className="loading_wrapper">
                                            <ScaleLoader loading={loading} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div className="mw-100 clearfix text-end">
                <Button onClick={handleOpenDeleteMigrationModal} variant="danger" className="d-inline-block m-1">
                    Delete
                </Button>
                <Button onClick={handleOpenSelectTargetStackModal} variant="success" className="d-inline-block m-1">
                    Select Target Stack
                </Button>
                <Button onClick={handleOpenViewSftpCredentialsModal} type="button" variant="success" className="d-inline-block m-1">
                    View SFTP Credentials
                </Button>
                <Button onClick={handleOpenEditDetailsModal} variant="success" className="d-inline-block m-1">
                    Edit Details
                </Button>
                {Modals.deleteMigrationModal(migration, showDeleteMigrationModal, handleCloseDeleteMigrationModal, handleSubmitDeleteMigrationModal)}
                {Modals.viewSftpCredentialsModal(showViewSftpCredentialsModal, handleCloseViewSftpCredentialsModal, sftpUsername, sftpPassword, sftpInternalEndpoint, sftpInternalCodeString, sftpExternalEndpoint, sftpExternalCodeString)}
                {Modals.selectTargetStackModal(assignableStacks, showSelectTargetStackModal, handleCloseSelectTargetStackModal)}
                {Modals.editDetailsModal(migration, showEditDetailsModal, handleCloseEditDetailsModal, handleSubmitEditDetailsModal)}
            </div>

            <div className="mw-100 clearfix mt-5">
                <h2>
                    Migration workflow
                </h2>

                <hr />
                <Tabs justify>
                    <Tab eventKey="1" title={<><Badge className="bg-secondary">1</Badge><p>Migration Questionnaire</p></>}>
                        {Step1MigrationQuestionnaire(client.clientId)}
                    </Tab>
                    <Tab eventKey="2" title={<><Badge className="bg-secondary">2</Badge><p>Provision SFTP</p></>}>
                        {Step2ProvisionSftp(client.clientId,Utils.formatRegion(migration.dataMigrationRegion))}
                    </Tab>
                    <Tab eventKey="3" title={<><Badge className="bg-secondary">3</Badge><p>Migrate Fileshare</p></>}>
                        {Step3MigrateFileshare()}
                    </Tab>
                    <Tab eventKey="4" title={<><Badge className="bg-secondary">4</Badge><p>Migrate Databases</p></>}>
                        {Step4MigrateDatabase()}
                    </Tab>
                    <Tab eventKey="5" title={<><Badge className="bg-secondary">5</Badge><p>Deploy Stack</p></>}>
                        {Step5DeployStack()}
                    </Tab>
                    <Tab eventKey="6" title={<><Badge className="bg-secondary">6</Badge><p>Configure Apps</p></>}>
                        {Step6ConfigureApps()}
                    </Tab>
                    <Tab eventKey="7" title={<><Badge className="bg-secondary">7</Badge><p>Deploy Fleet</p></>}>
                        {Step7DeployFleet()}
                    </Tab>
                    <Tab eventKey="8" title={<><Badge className="bg-secondary">8</Badge><p>Update Fileshare</p></>}>
                        {Step8UpdateFileshare()}
                    </Tab>
                    <Tab eventKey="9" title={<><Badge className="bg-secondary">9</Badge><p>Update Databases</p></>}>
                        {Step9UpdateDatabases()}
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}