import { useNavigate, NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import Auth from "../lib/auth";

export default function Nav() {
    const navigate = useNavigate();

    function createNavButton(name, link, type='', onlyHighlightEnd = false) {
        if (name === 'Terminal Servers' && !Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins')) {
            return;
        }

        if (name === 'Client Services System Access' && !Auth.groupCheck('ClientServicesSystemAccessUsers')) {
            return;
        }

        if (name === 'Client Services System Access Administration' && !Auth.groupCheck('ClientServicesSystemAccessAdmins')) {
            return;
        }

        if (!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            if (name === 'Clients' || name === 'Pods' || name === 'Stacks' || name === 'Migrations' || name === 'Terminal Servers') {
                return;
            }
        }

        return (type !== "dropdown") ? (
            <NavLink className={`nav-item col-6 col-md-auto link mx-2`} onClick={() => navigate(link)} to={link} end={onlyHighlightEnd} key={'desktop-' + name.toLowerCase()}>
                {name}
            </NavLink>
        ) : (
            <Dropdown.Item onClick={() => navigate(link)} className={`lh-base ${link}`} key={'mobile-' + name.toLowerCase()}>
                {name}
            </Dropdown.Item>
        )
    }

    const groups = (localStorage.getItem('groups')) ? JSON.parse(localStorage.getItem('groups')) : [];

    return (
        <nav className="navbar navbar-expand-md position-fixed" aria-label="Main navigation">
            <a className="navbar-brand p-0 me-2" href="/" aria-label="NGE Environment Manager">
                <img src="/assets/img/logo.svg" />
            </a>
            <div className="navbar-toggler dropdown border-0" data-bs-toggle="navbar-collapse">
                <Dropdown>
                    <Dropdown.Toggle className="text-white bg-transparent border-0">
                        Main Menu
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {createNavButton('Clients', 'clients', 'dropdown')}
                        {createNavButton('Pods', 'pods', 'dropdown')}
                        {createNavButton('Stacks', 'stacks', 'dropdown')}
                        {createNavButton('Migrations', 'migrations', 'dropdown')}
                        {createNavButton('Terminal Servers', 'terminal-servers', 'dropdown')}
                        {createNavButton('Client Services System Access', 'client-services-system-access', 'dropdown', true)}
                        {createNavButton('Client Services System Access Admin', 'client-services-system-access/admin', 'dropdown')}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className="collapse navbar-collapse" id="bdNavbar">
                <ul className="navbar-nav flex-row flex-nowrap text-nowrap bd-navbar-nav bg">
                    {createNavButton('Clients', 'clients')}
                    {createNavButton('Pods', 'pods')}
                    {createNavButton('Stacks', 'stacks')}
                    {createNavButton('Migrations', 'migrations')}
                    {createNavButton('Terminal Servers', 'terminal-servers')}
                    {createNavButton('Client Services System Access', 'client-services-system-access', 'nav', true)}
                    {createNavButton('Client Services System Access Admin', 'client-services-system-access/admin')}
                </ul>
            </div>
            <Dropdown id="ngeHamburger">
                <Dropdown.Toggle className="text-white bg-transparent border-0">
                    <div style={{color:"white"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="bi"
                             fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                        </svg>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item disabled="disabled" className="border-bottom">
                        <h5 className="m-1 fw-bold">
                            {localStorage.getItem('name')} ({localStorage.getItem('email')})
                        </h5>
                        <p className="text-info">
                            Groups:
                            <ul>
                                {groups.map(g => (
                                    <li key={g}>{g.match(/[A-Z][a-z]+/g).join(' ')}</li>
                                ))}
                            </ul>
                        </p>
                    </Dropdown.Item>
                    <Dropdown.Item target="_blank" href="https://nextgenhealthcare.atlassian.net/wiki/x/7ouiF" key="user-guide">
                        User&#160;Guide
                    </Dropdown.Item>
                    <Dropdown.Item target="_blank" href="https://nextgenhealthcare.atlassian.net/secure/CreateIssue.jspa?issuetype=1&pid=18444" key="report-bug">
                        Report&#160;Bug
                    </Dropdown.Item>
                    <Dropdown.Item target="_blank" href="https://nextgenhealthcare.atlassian.net/secure/CreateIssue.jspa?issuetype=2&pid=18444" key="feature-request">
                        Feature&#160;Request
                    </Dropdown.Item>
                    <Dropdown.Item className="text-danger fw-bold border-top border-bottom" href="/?signOut=true" key="sign-out">
                        Sign Out
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </nav>
    );
}