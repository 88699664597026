

import { useNavigate } from "react-router-dom";

import { ScaleLoader } from "react-spinners";
import Auth from "../../../lib/auth";

import Graphql from "../../../lib/graphql";
import Paginate from "../../../lib/paginate";
import Search from "../../../lib/search";
import Queries from "../../../lib/terminalservers/queries";
import Modals from "../../../lib/terminalservers/modals";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Utils from "../../../lib/utils";


export default function TerminalServersClientDetails() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const { clientId } = useParams();
    const [client, setClient] = useState({});
    const [instanceId, setInstanceId] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [stopModalOpen, setStopModalOpen] = useState(false)
    const [onboardClient, setOnboardClient] = useState(false);
    const [replaceModalOpen, setReplaceModalOpen] = useState(false);
    const [replicateModalOpen, setReplicateModalOpen] = useState(false);
    const [snapshotModalOpen, setSnapshotModalOpen] = useState(false);
    const [confirmPackagesModalOpen, setConfirmPackagesModalOpen] = useState(false);
    const [createDescriptionModalOpen, setCreateDescriptionModalOpen] = useState(false);
    const [state, setState] = React.useState(null);
    const [terminalServers, setTerminalServers] = useState([]);
    const [addTerminalServersModalOpen, setAddTerminalServersModalOpen] = useState(false);
    const [recipes, setRecipes] = React.useState({});
    const [migration, setMigration] = React.useState({});
    const [awsAccountId, setAWSAccountId] = React.useState();
    const [awsRegion, setAWSRegion] = React.useState();
    const [customerInventory, setCustomerInventory] = useState([]);
    const [description, setDescription] = useState('');
    const [replicatedNumber, setReplicatedNumber] = useState(0);
    const [amiList, setAMIList] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    const closeConfirmPackagesModalOpen = () => {
        setConfirmPackagesModalOpen(false);
    }

    const openConfirmPackagesModalOpen = () => {
        setConfirmPackagesModalOpen(true);
    }
    const closeReplicateModal = () => {
        setReplicateModalOpen(false);
    };
    const openReplicateModal = (instanceId) => {
        setInstanceId(instanceId)
        setReplicateModalOpen(true);
    }

    const openOnboardClient = () => {
        setOnboardClient(true)
    }

    const closeOnboardClient = () => {
        setOnboardClient(false)
    }

    const [isProcessing, setIsProcessing] = useState(false); // Track if the process is running

    
    // Function to handle changes in the text box
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };



    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleOnChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    const refreshUpdateSoftwareInventory = () => {
        setState(updateSoftwareInventory(recipes));
    };


    // Define help bar styles
    const helpBarStyles = {
        position: 'fixed',
        right: 0,
        top: '50px',
        width: '300px',
        height: isExpanded ? 'auto' : '20px',
        backgroundColor: isExpanded ? '#f0f0f0' : 'initial',
        padding: '20px',
        boxShadow: isExpanded ? '0 0 10px rgba(0,0,0,0.1)' : 'initial',
        cursor: 'pointer',
        transition: 'height 0.3s ease-in-out',
        zIndex: "1000"
    };

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const filterBySearchCriteria = (searchInput) => (ts) => {
        if (!ts) return false; // Ignore null entries
        const searchLowerCase = searchInput.toLowerCase();

        const instanceId = ts.instanceId?.toLowerCase() || '';
        const amiId = get_ami_id(ts.terminalServerAmiId)?.toLowerCase() || '';
        const amiType = get_ami_type(ts.terminalServerAmiId)?.toLowerCase() || '';
        const instanceState = ts.instanceState?.toLowerCase() || '';

        const matchesSearchCriteria =
            instanceId.includes(searchLowerCase) ||
            amiId.includes(searchLowerCase) ||
            amiType.includes(searchLowerCase) ||
            instanceState.includes(searchLowerCase);

        return matchesSearchCriteria;
    };

    // Function to toggle expansion state for Help Bar
    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const styles = {
        mainHeader: {
            fontSize: '2.5rem',
        },
        sectionHeader: {
            fontSize: '1.2rem'
        },
    };
    
    async function createMigrationRecipe(recipeId, migrationId) {
        let built = false;
        const listMigrationRecipes = `
                query ListMigrationRecipes(
                    $filter: ModelMigrationRecipesFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listMigrationRecipes(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                    ) {
                    items {
                        id
                        recipeId
                        migrationId
                        _version
                    }
                    nextToken
                    }
                }
         `;

        const variables = {
            filter: {
                migrationId: { eq: migrationId },
                recipeId: { eq: recipeId },
            }
        };

        const get_migration_recipes = await Graphql.query(listMigrationRecipes, variables);

        if (get_migration_recipes !== undefined && get_migration_recipes.length !== 0) {
            const migrationRecipeDetails = {
                id: get_migration_recipes[0].id,
                _version: get_migration_recipes[0]._version,
                recipeId: recipeId,
                migrationId: migrationId
            };

            const updateMigrationRecipe = Queries.updateMigrationRecipes(migrationRecipeDetails)
            built = true;
        }

        if (built === false) {
            const migrationRecipeDetails = {
                recipeId: recipeId,
                migrationId: migrationId
            };

            const newMigrationRecipe = Queries.createMigrationRecipes(migrationRecipeDetails);

        }
    }

    async function buildInventory() {
        const tsDescription = document.getElementById('tsDescription').value;

        if (!tsDescription) {
            return alert('Description is required.');
        }

        try {
            const get_recipe = recipes

            for (const [key, value] of Object.entries(Modals.cur_packages)) {
                for (let i = 0; i < get_recipe.length; i++) {

                    if (value.value.id === get_recipe[i].id) {
                        createMigrationRecipe(get_recipe[i].id, migration.id)
                        break;
                    }
                }
            }

            const payload = {
                event_name: "basets",
                client_id: client.clientId,
                migration_id: migration.id,
                description: tsDescription,
                client_account_id: awsAccountId,
                region: awsRegion
            };

            const runEventOrchestrator = `
                query RunEventOrchestrator($payload: eventPayload) {
                    runEventOrchestrator(payload: $payload)
                }
            `;
            
            const variables = { payload };

            // Execute the GraphQL query
            await Graphql.query(runEventOrchestrator, variables);

            refreshUpdateSoftwareInventory();
            closeReplicateModal();
            closeConfirmPackagesModalOpen();
            closeCreateDescriptionModal();
            alert("Processing the creation of a base terminal server.");
        } catch (error) {
            console.error('Error creating base terminal servers', error);

            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                alert(`Error creating base terminal servers: ${error.errors[0].message}`);
            } else {
                alert('Error creating base terminal servers. Please try again.');
            }
        }
    }
    
    function managePackages(recipes) {

        const isButtonDisabled = does_build_basets_get_button()

        const button = (
            <Button
                id="buildInventory"
                onClick={openConfirmPackagesModalOpen}
                size="small"
                variation="primary"
                disabled={isButtonDisabled}
            >
                Build Base Terminal Server
            </Button>
        );

        return (
            <div>
                {Modals.packagesTableComponent(recipes, button, clientId)}
            </div>
        );
    }


    function updateSoftwareInventory(recipes) {
        return (
            <div className="mw-100 mt-5 clearfix">
                <h2 style={styles.sectionHeader}>Update Software Inventory</h2> <hr/>
                {managePackages(recipes)}
            </div>
        );
    }
    
    const handleYesClick = async () => {
        if (!isProcessing) {
            setIsProcessing(true); // Disable the button after first click

            // Show an alert immediately when the button is clicked
            alert('The function is being executed,PLEASE WAIT for about 30seconds');

            // Call addExistingEC2Instances function and wait for it to complete
            await addExistingEC2Instances();

            // Close the modal after the function completes
            closeOnboardClient();
            setIsProcessing(false);
        }
    };

    const addExistingEC2Instances = async () => {
        try {
            // Construct the payload the right input parameters
            const payload = {
                event_name: 'migrateclient',
                client_account_id: awsAccountId,
                region: awsRegion,
                client_id: client.clientId,
                client_name: client.clientName,
                migration_id: migration.id
            };
            
            // Define the GraphQL query
            const runEventOrchestrator = `
                query RunEventOrchestrator($payload: eventPayload) {
                    runEventOrchestrator(payload: $payload)
                }
            `;

            const variables = { payload };

            // Execute the GraphQL query
            await Graphql.query(runEventOrchestrator, variables);

            // Handle GraphQL response
           
            alert("Finding additional terminal servers. Click OK, and REFRESH the page after 1 minute.");
            closeOnboardClient();
        } catch (error) {
            console.error("Error adding client ID:", error.message || error);
            alert("An error occurred while adding terminal servers. Please try again.");
        }
    };
    
    function addExistingTsModal() {
        return (
            <Modal show={onboardClient} onHide={closeOnboardClient} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Are you sure you wish to onboard ${clientId}?`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="fw-bold text-danger">Warning:</span>
                    <br />If you currently have other processes running, do NOT onboard the client.<br /><br />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={isProcessing} onClick={handleYesClick}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => closeOnboardClient()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    


    const stopTerminalServer = async () => {
        try {
            setReplicatedNumber(0)
            let response = await singleAction('stopinstances')
            alert(instanceId + " stopped");
        } catch (error) {
            console.error('Error stopping terminal servers', error);

            // Check if there is an error with a message
            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                // Display the error message to the user
                alert(`Error stopping servers: ${error.errors[0].message}`);
            } else {
                // Display a generic error message
                alert('Error stopping servers. Please try again.');
            }

        }
        closeStopModal()
    }

    const singleAction = async (event_name, description = "") => {
        const payload = {
            event_name: event_name,
            migration_id: migration.id,
            client_id: client.clientId,
            client_account_id: awsAccountId,
            instance_id: instanceId,
            region: awsRegion
        };

        if (description !== "") {
            payload['description'] = description
        }

        if (replicatedNumber !== 0) {
            for (let i = 0; i < replicatedNumber; i++) {

                // Define the GraphQL query
                const runEventOrchestrator = `
                    query RunEventOrchestrator($payload: eventPayload) {
                        runEventOrchestrator(payload: $payload)
                    }
                `;

                const variables = { payload };

                // Execute the GraphQL query
                const response = await Graphql.query(runEventOrchestrator, variables);
            }
        }
        else if (replicatedNumber === 0) {
            const runEventOrchestrator = `
                    query RunEventOrchestrator($payload: eventPayload) {
                        runEventOrchestrator(payload: $payload)
                    }
                `;

            const variables = { payload };

            // Execute the GraphQL query
            const response = await Graphql.query(runEventOrchestrator, variables);
        }
    }

    const replaceTSClick = async () => {
        try {
            setReplicatedNumber(0)
            let response = await singleAction("replacets");
            alert('Replacing terminal servers');
        } catch (error) {
            console.error('Error replacing terminal server', error);

            // Check if there is an error with a message
            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                // Display the error message to the user
                alert(`Error replacing server: ${error.errors[0].message}`);
            } else {
                // Display a generic error message
                alert('Error replacing server. Please try again.');
            }
        }
        closeReplaceModal();
    }

    const replicateLaunchButtonClick = async () => {
        try {
            let response = await singleAction('replicatets');
            alert('Terminal servers replicated successfully!');
            closeAddTerminalServersModal();
        } catch (error) {
            console.error('Error replicating terminal servers', error);

            // Check if there is an error with a message
            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                // Display the error message to the user
                alert(`Error replicating servers: ${error.errors[0].message}`);
            } else {
                // Display a generic error message
                alert('Error replicating servers. Please try again.');
            }

        }
        closeReplicateModal();
    };

    const snapshotTerminalServerClick = async () => {
        try {
            setReplicatedNumber(0)
            let response = await singleAction("snapshot", description)
            alert(`Instance ID: ${instanceId} snapshot taken successfully!`);
        } catch (error) {
            console.error('Error taking terminal servers snapshot', error);

            // Check if there is an error with a message
            if (error.errors && error.errors.length > 0 && error.errors[0].message) {
                // Display the error message to the user
                alert(`Error taking terminal servers snapshot: ${error.errors[0].message}`);
            } else {
                // Display a generic error message
                alert('Error taking terminal servers snapshot. Please try again.');
            }

        }
        closeSnapshotModal();
    };
    

    const does_build_basets_get_button = () => {
        const isButtonDisabled = terminalServers.some(ts => {
            const amiType = get_ami_type(ts.terminalServerAmiId);
            const instanceState = ts.instanceState;

            if (!amiType || !instanceState) {
                return false;
            }
            const isInstanceRunning = instanceState.toLowerCase() === "running";

            return amiType === 'CustomerBaseline' && isInstanceRunning

        });

        return isButtonDisabled;
    }

    function handleStepChange(e) {
        setReplicatedNumber(e);
    }
    function openReplaceModal(instanceId) {
        setInstanceId(instanceId)
        setReplaceModalOpen(true);
    }

    function closeReplaceModal() {
        setReplaceModalOpen(false);
    }

    function openStopModal(instanceId) {
        setInstanceId(instanceId);
        setStopModalOpen(true);
    }

    function get_region(region) {
        if (region) {
            return region.replaceAll("_", "-");
        }
    }

    function get_ami_type(ami_id) {
        for (let i = 0; i < amiList.length; i++) {
            if (typeof amiList[i] !== "undefined" && amiList[i].id == ami_id) {
                return amiList[i].amiType;
            }
        }
    }

    function get_ami_id(ami_id) {
        for (let i = 0; i < amiList.length; i++) {
            if (typeof amiList[i] !== "undefined" && amiList[i].id == ami_id) {
                return amiList[i].name;
            }
        }
    }

    function closeStopModal() {
        setStopModalOpen(false);
    }


    function closeSnapshotModal() {
        setSnapshotModalOpen(false);
    }


    function openSnapshotModal(instanceId) {
        setInstanceId(instanceId);
        setSnapshotModalOpen(true);
    }

    function closeAddTerminalServersModal() {
        setAddTerminalServersModalOpen(false);
    }

    function closeConfirmPackagesModal() {
        setConfirmPackagesModalOpen(false);
    }

    function openCreateDescriptionModal() {
        setConfirmPackagesModalOpen(false);
        setCreateDescriptionModalOpen(true);
    }

    function closeCreateDescriptionModal() {
        setCreateDescriptionModalOpen(false);
    }

    function toggleAllColumns() {
        let hiddenColumnByDefault = document.getElementsByClassName('hiddenColumnByDefault');
        let toggleColumnsButton = document.getElementById('toggleColumnsButton');
        let terminalServerInfoTooltipIcon = document.getElementsByClassName('terminalServerInfoTooltipIcon');
        let terminalServerInfoTooltip = document.getElementsByClassName('terminalServerInfoTooltip');

        for (let i = 0; i < hiddenColumnByDefault.length; i++) {
            if (!hiddenColumnByDefault[i].style.display || hiddenColumnByDefault[i].style.display === 'none') {
                hiddenColumnByDefault[i].style.display = 'table-cell';
                toggleColumnsButton.innerHTML = 'Toggle Columns (Show Less)';
            } else {
                hiddenColumnByDefault[i].style.display = 'none';
                toggleColumnsButton.innerHTML = 'Toggle Columns (Show More)';
            }
        }

        for (let j = 0; j < terminalServerInfoTooltip.length; j++) {
            terminalServerInfoTooltip[j].style.display = 'none';

            if (hiddenColumnByDefault[0].style.display !== 'none') {
                terminalServerInfoTooltipIcon[j].style.display = 'none';
            } else {
                terminalServerInfoTooltipIcon[j].style.display = 'initial';
            }
        }
    }

    function buildTsServer(){
        return (
            <Modal style={customStyles} appElement={document.getElementById('app')} show={confirmPackagesModalOpen} onHide={closeConfirmPackagesModal}>
            {Modals.modalCloseButton(closeConfirmPackagesModalOpen)}
                <Modal.Header closeButton>
                    <Modal.Title>
                     Rebuild Terminal Servers
                    </Modal.Title>
                </Modal.Header>
                <div>
                    {get_current_customer_inventory()}
                </div><br />
                <p>Are you sure you want to continue?</p>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    padding: '10px 0',
                    borderTop: '1px solid #ddd',
                }}>
                    <Button 
                        size="small" 
                        variation="primary" 
                        onClick={openCreateDescriptionModal}
                    >
                        Yes
                    </Button>
                    <Button 
                        size="small" 
                        onClick={closeConfirmPackagesModalOpen}
                    >
                        Cancel
                    </Button>
                </div>
        </Modal>
        );
    }

    function toggleTerminalServerInfoTooltip(elementId) {
        let terminalServerInfoTooltipId = 'terminalServerInfoTooltip----' + elementId.split('----')[1];
        let terminalServerInfoTooltip = document.getElementById(terminalServerInfoTooltipId);
        if (!terminalServerInfoTooltip.style.display || terminalServerInfoTooltip.style.display === 'none') {
            terminalServerInfoTooltip.style.display = 'block';
        } else {
            terminalServerInfoTooltip.style.display = 'none';
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const does_instance_get_buttons = (curInstanceId, curAmiId, curInstanceState) => {

        const isInstanceStopped = curInstanceState === "stopped" || curInstanceState === "STOPPED" || curInstanceState === "Stopped";

        if (get_ami_type(curAmiId) === "DeprecatedAMI") {
            return (
                <>
                    <Button style={{ margin: ".5em auto" }} onClick={() => openStopModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Stop</Button>&#160;&#160;
                </>
            )
        }
        else if (get_ami_type(curAmiId) === "CustomerBaseline") {
            return (
                <>
                    <Button style={{ margin: ".5em auto" }} onClick={() => openSnapshotModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Snapshot</Button>&#160;&#160;
                    <Button style={{ margin: ".5em auto" }} onClick={() => openStopModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Stop</Button>&#160;&#160;
                </>
            )
        }
        else if (get_ami_type(curAmiId) === "ModifyBaseline") {
            return (
                <>
                    <Button style={{ margin: ".5em auto" }} onClick={() => openReplaceModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Replace</Button>&#160;&#160;
                    <Button style={{ margin: ".5em auto" }} onClick={() => openStopModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Stop</Button>&#160;&#160;
                    <Button style={{ margin: ".5em auto" }} onClick={() => openReplicateModal(curInstanceId)} size="small" variation="primary" disabled={isInstanceStopped}>Replicate</Button>
                </>
            )
        }
    }

    useEffect(() => {
        const fetchRecipesData = async () => {
            const recipesQuery = `
                query ListRecipes(
                    $filter: ModelRecipeFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            name
                            arn
                            description
                            required
                            id
                            createdAt
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            __typename
                            migrations {
                                items {
                                    recipe {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                        nextToken
                        startedAt
                        __typename
                    }
                }
            `;

            const recipesVariables = { filter: {}};
            const recipesData = await Graphql.query(recipesQuery, recipesVariables);
            if (recipesData) {
                setRecipes(recipesData.listRecipes.items);
            }
        };

            const fetchClientData = async () => {
                const clientQuery = `
                    query GetClient($clientId: ID!) {
                        getClient(clientId: $clientId) {
                            clientId
                            clientName
                            stacks {
                                items {
                                    pod {
                                        awsAccountId
                                        awsRegion
                                    }
                                }
                            }
                        }
                    }
                `;
                const clientVariables = { clientId };
                const clientData = await Graphql.query(clientQuery, clientVariables);
                if (clientData) {
                    setClient(clientData.getClient);
                    const stack = clientData.getClient.stacks.items;
                    if (stack.length > 0) {
                        const pod = stack[0].pod;
                        setAWSAccountId(pod.awsAccountId);
                        setAWSRegion(pod.awsRegion.replaceAll('_', '-'));
                    }
                }
            };
    
            // Fetch Migration Data
            const fetchMigrationData = async () => {
                try {
                    const migrationQuery = `
                        query ListMigrations($filter: ModelMigrationFilterInput, $limit: Int) {
                            listMigrations(filter: $filter, limit: $limit) {
                                items {
                                    id
                                    clientMigrationsClientId
                                    terminalServers {
                                        items {
                                            instanceId
                                            instanceName
                                            instanceState
                                            buildDate
                                            description
                                            region
                                            migrationTerminalServersId
                                            terminalServerAmiId
                                            ami {
                                                id
                                                amiType
                                                name
                                            }
                                        }
                                    }
                                }
                                nextToken
                            }
                        }
                    `;
            
                    const migrationVariables = {
                        filter: { clientMigrationsClientId: { eq: clientId } }
                    };
            
                    const migrationData = await Graphql.query(migrationQuery, migrationVariables);
                    

                    // Check the migration data
                    if (migrationData && migrationData.listMigrations && migrationData.listMigrations.items.length > 0) {
                        const migration = migrationData.listMigrations.items[0];

                        setMigration(migration);
                        console.log("migrationid:", migration.id); // Log the migration ID

                        // Extract terminal servers and handle them
                        const terminalServers = migration.terminalServers?.items || [];
                        if (terminalServers.length > 0) {
                            let ami_list = [];
                            // filter null or undefined items
                            const filterTerminalServers = terminalServers.filter(ts => ts);

                            for (let i = 0; i < filterTerminalServers.length; i++) {
                                ami_list.push(await filterTerminalServers[i].ami);
                            }
                            
                            setAMIList(ami_list)
                            setTerminalServers(filterTerminalServers);
                            console.log("Terminal Servers:", filterTerminalServers);
                        } else {
                            console.warn("No terminal servers found for the migration.");
                            setTerminalServers([]); // Set an empty array if none exist
                        }
                    } else {
                        console.warn("No migration found for the specified client ID.");
                        setMigration(null);
                        setInstanceId("No terminal servers");
                        setTerminalServers([]);
                    }
                } catch (error) {
                    console.error("Error fetching migration and terminal server data:", error);
                    setMigration(null);
                    setTerminalServers([]);
                } finally {
                    setIsLoading(false);
                }
            };


             // Fetch all data
            const fetchData = async () => {
                try {
                    const clientData = await fetchClientData(); // Fetch client data first
            
                    // Sequential execution ensures fetchMigrationData completes before fetchRecipesData starts
                    await fetchMigrationData(clientData);
                    await fetchRecipesData();
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            
            fetchData();
            
    }, [clientId]);
    

    const get_current_customer_inventory = () => {
        const temp_mapping = [];
        for (let [key, value] of Object.entries(Modals.cur_packages)) {
            temp_mapping.push(
                <li>
                    {value.key}
                </li>
            );
        };

        return (
            <><p>
                The following packages will be added to the new terminal server:
                <ul style={{ color: "green", fontWeight: "bold" }}>
                    {temp_mapping}
                </ul>
            </p></>
        )
    }
    
    const [stepperValue, setStepperValue] = useState(1); // Default value for the stepper

    const handleStepperIncrement = () => {
        if (stepperValue < 21) {
            setStepperValue(stepperValue + 1);
        }
    };

    // Handle decrement (if not at min 1)
    const handleStepperDecrement = () => {
        if (stepperValue > 1) {
            setStepperValue(stepperValue - 1);
        }
    };

    const handleStepInputChange = (e) => {
        let value = parseInt(e.target.value, 10);
        if (isNaN(value)) {
            value = 1; // Fallback to 1 if input is not a valid number
        } else if (value < 1) {
            value = 1; // Prevent values below 1
        } else if (value > 21) {
            value = 21; // Prevent values above 21
        }
        setStepperValue(value);
    };
    


    return (
        <div>
                    <style>
                        {`
                            .help-bar h2 {
                                color: black !important;
                            }
                        `}
                    </style>
                    <div>
                        <h1 style={styles.mainHeader}>{`Terminal Servers - ${client.clientName}`}</h1>
                        <hr />
                    </div>
                    <div style={{ ...helpBarStyles, textDecoration: isExpanded ? 'none' : 'underline' }} onClick={toggleExpansion} className="help-bar" >
                        <h2 className="fw-bold" style={{textAlign: "right", fontSize: "22px"}}><img style={{ height: "1em", marginRight: "2px"}} src="/assets/img/help-icon.png" alt="Help icon"/> Help</h2>
                        {isExpanded && (
                            <div style={{ height: 'auto', padding: '20px', maxHeight: '80vh', overflow: 'auto', cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                <p>This is for understanding how the process works.</p>
                                <p><strong><u>Build Base Terminal Server</u></strong>:</p>
                                <p>Refer to this <a href="https://nextgenhealthcare.atlassian.net/wiki/x/AYAKJQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a> to learn more on build base ts workflow.</p>
                                <p><strong><u>Add Existing Terminal Servers</u></strong>:</p>
                                <p>Refer to this <a href="https://nextgenhealthcare.atlassian.net/wiki/x/_oReIQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a> to learn more on adding existing ts workflow.</p>
                                <p><strong><u>Actions Button</u></strong>:</p>
                                <p>Replace instance workflow - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/AQAAJ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Replicate instance workflow - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/AYBpJQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Snapshot instance workflow - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/AYD9J" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Stop instance workflow - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/WJBoIw" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p><strong><u>Migrating Existing Clients</u></strong>:</p>
                                <p>Refer to this <a href="https://nextgenhealthcare.atlassian.net/wiki/x/_oReIQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a> to learn more on migrating existing clients workflow.</p>
                                <p><strong><u>Other useful information</u></strong>:</p>
                                <p>Lynx 2.0 User documentation - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/5VU5IQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Prerequisites and Onboarding Lynx 2.0 - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/IFw6IQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                                <p>Lynx 2.0 Definitions - <a href="https://nextgenhealthcare.atlassian.net/wiki/x/P4JdIQ" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue' }}>link</a></p>
                            </div>
                        )}
                    </div>
                    <div className="mw-100 mt-5 clearfix">
                        {updateSoftwareInventory(recipes)}
                    </div>

                    <div className="mw-100 mt-5 clearfix">
                        <h2 style={styles.sectionHeader}>Deployed Terminal Servers</h2>
                        <hr />
                    </div>
                    <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
                        <button type="button" className="btn btn-success d-inline-block mt-3 mb-3" onClick={openOnboardClient}>Add Existing Terminal Servers</button>
                        {addExistingTsModal()}
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        style={{ marginBottom: '1rem', display: 'block', width: '100%' }}
                    />
                    <Button style={{ width: "250px" }} id="toggleColumnsButton" size="small" onClick={toggleAllColumns}>
                        Toggle Columns (Show More)
                    </Button>
                    <table className="lynx-table table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>Instance</th>
                                <th>Build Date</th>
                                <th>Description</th>
                                <th className="hiddenColumnByDefault">Region</th>
                                <th>AMI Type</th>
                                <th className="hiddenColumnByDefault">AMI ID</th>
                                <th className="hiddenColumnByDefault">Health</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {terminalServers.length === 0 ? (
                                <tr>
                                    <td colSpan="8" style={{ textAlign: 'center' }}>(No Results)</td>
                                </tr>
                            ) : (
                                terminalServers
                                    .filter(filterBySearchCriteria(searchInput))
                                    .map(ts => (
                                        <tr key={ts.instanceId} style={{ cursor: 'pointer' }}>
                                            <td>
                                                {ts.instanceId}&#160;&#160;
                                                <img
                                                    id={`terminalServerInfoTooltipIcon----${ts.instanceId}`}
                                                    onClick={(e) => toggleTerminalServerInfoTooltip(e.target.id)}
                                                    className="terminalServerInfoTooltipIcon"
                                                    style={{ height: '1em'}}
                                                    src="/assets/img/info-icon.png"
                                                    alt="info-icon"
                                                />
                                                <div
                                                    id={`terminalServerInfoTooltip----${ts.instanceId}`}
                                                    className="terminalServerInfoTooltip"
                                                    style={{
                                                        display: 'none',
                                                        backgroundColor: '#f9f9f9',
                                                        border: '1px solid #ccc',
                                                        padding: '10px',
                                                        
                                                    }}
                                                >
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Instance ID:</td>
                                                                <td>{ts.instanceId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Region:</td>
                                                                <td>{get_region(ts.region)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Status:</td>
                                                                <td>{ts.instanceState.toUpperCase()}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>AMI Type:</td>
                                                                <td>{get_ami_type(ts.terminalServerAmiId)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>AMI ID:</td>
                                                                <td>{get_ami_id(ts.terminalServerAmiId)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                            <td>{Utils.timestampToStringFormatted(ts.buildDate)}</td>
                                            <td>{ts.description}</td>
                                            <td className="hiddenColumnByDefault">{get_region(ts.region)}</td>
                                            <td>{get_ami_type(ts.terminalServerAmiId)}</td>
                                            <td className="hiddenColumnByDefault">{get_ami_id(ts.terminalServerAmiId)}</td>
                                            <td className="hiddenColumnByDefault">
                                                <span
                                                    style={{
                                                        color: ts.instanceState.toUpperCase() === 'RUNNING' ? 'green' : 'red',
                                                    }}
                                                >
                                                    {ts.instanceState.toUpperCase()}
                                                </span>
                                            </td>
                                            <td>{does_instance_get_buttons(ts.instanceId, ts.terminalServerAmiId, ts.instanceState)}</td>
                                        </tr>
                                    ))
                            )}
                        </tbody>
                     </table>
                     {/* Conditionally Render Modals Based on State */}
                     {snapshotModalOpen &&
                        Modals.addSnapshotTsModal({
                            snapshotModalOpen,
                            closeSnapshotModal,
                            instanceId,
                            description,
                            handleDescriptionChange,
                            snapshotTerminalServerClick,
                        })}
                    {replaceModalOpen && 
                        Modals.addReplaceTsModal({
                            replaceModalOpen,
                            closeReplaceModal,
                            instanceId,
                            replaceTSClick
                        })}
                    {replicateModalOpen && Modals.addReplicateTsModal({
                        replicateModalOpen,
                        closeReplicateModal,
                        instanceId,
                        replicateLaunchButtonClick,
                        stepperValue,
                        handleStepperDecrement,
                        handleStepperIncrement,
                        handleStepInputChange
                        })}
                    {stopModalOpen && Modals.addStopTsModal({
                        stopModalOpen,
                        closeStopModal,
                        instanceId,
                        stopTerminalServer
                    })}
                    {confirmPackagesModalOpen && buildTsServer()}
                    {createDescriptionModalOpen && 
                        Modals.createDescriptionModal({
                            createDescriptionModalOpen, 
                            closeCreateDescriptionModal, 
                            buildInventory
                        })
                    }
                </div>
    );
}