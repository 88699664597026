import {Badge, Tab, Tabs} from "react-bootstrap";

export default function Step4MigrateDatabase() {
    return (
        <>
            <Tabs justify className="mt-3">
                <Tab eventKey="4-1" title={<><Badge className="bg-secondary">4.1</Badge><p>Create Backups</p></>}>
                    Step 4.1 goes here
                </Tab>
                <Tab eventKey="4-2" title={<><Badge className="bg-secondary">4.2</Badge><p>Transfer Backups to S3</p></>}>
                    Step 4.2 goes here
                </Tab>
                <Tab eventKey="4-3" title={<><Badge className="bg-secondary">4.3</Badge><p>Transfer Backups to Destination Server</p></>}>
                    Step 4.3 goes here
                </Tab>
                <Tab eventKey="4-4" title={<><Badge className="bg-secondary">4.4</Badge><p>Restore SQL Backups</p></>}>
                    Step 4.4 goes here
                </Tab>
                <Tab eventKey="4-5" title={<><Badge className="bg-secondary">4.5</Badge><p>Run Post-Migration Script</p></>}>
                    Step 4.5 goes here
                </Tab>
            </Tabs>
        </>
    );
}