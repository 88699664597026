import {Badge, Tab, Tabs} from "react-bootstrap";

export default function Step8UpdateFileshare() {
    return (
        <>
            <Tabs justify className="mt-3">
                <Tab eventKey="8-1" title={<><Badge className="bg-secondary">8.1</Badge><p>Setup File Migration Utils</p></>}>
                    Step 8.1 goes here
                </Tab>
                <Tab eventKey="8-2" title={<><Badge className="bg-secondary">8.2</Badge><p>Transfer Files to S3</p></>}>
                    Step 8.2 goes here
                </Tab>
                <Tab eventKey="8-3" title={<><Badge className="bg-secondary">8.3</Badge><p>Transfer Files from S3 to Target File Share</p></>}>
                    Step 8.3 goes here
                </Tab>
            </Tabs>
        </>
    );
}