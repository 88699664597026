class Sort {
    static setSort(event, dataArray, key, direction = 'asc') {
        let clickedHeader = event.target;

        if (direction === 'desc' && !clickedHeader.classList.contains('desc')) {
            document.querySelectorAll('.sortableColumn').forEach((e) => e.classList.remove('asc'));
            clickedHeader.classList.add('desc');
        } else if (direction === 'asc' && !clickedHeader.classList.contains('asc')) {
            document.querySelectorAll('.sortableColumn').forEach((e) => e.classList.remove('desc'));
            clickedHeader.classList.add('asc');
        }

        if (direction === 'desc') {
            return dataArray.sort((a, b) => (a[key] > b[key] ? -1 : 1));
        }

        return dataArray.sort((a, b) => (a[key] > b[key] ? 1 : -1));
    }
}

export default Sort;