import React, { useEffect, useState } from 'react';
import Graphql from "../../../lib/graphql";
import Paginate from "../../../lib/paginate";
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from "react-spinners";
import Search from "../../../lib/search";
import Auth from "../../../lib/auth";
import PodQueries from "../../../lib/pod/queries";
import Modals from "../../../lib/pod/modals";
import Utils from "../../../lib/utils";

export default function PodList() {
    const [pods, setPods]                                       = useState([]);
    const [currentPage, setCurrentPage]                         = useState(1);
    const [totalPages, setTotalPages]                           = useState(1);
    const [visiblePods, setVisiblePods]                         = useState([]);
    const [loading, setLoading]                                 = useState(true);
    const [createPodModalOpen, setCreatePodModalOpen]           = useState(false);
    const [automateSqlPodModalOpen, setAutomateSqlPodModalOpen] = useState(false);

    const pageSize  = 25;
    const navigate  = useNavigate();

    const openCreatePodModal    = () => setCreatePodModalOpen(true);
    const closeCreatePodModal   = () => setCreatePodModalOpen(false);

    const openAutomateSqlPodModal   = () => setAutomateSqlPodModalOpen(true);
    const closeAutomateSqlPodModal  = () => setAutomateSqlPodModalOpen(false);

    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const fetchPods = async () => {
        const listPods = `
            query ListPods(
                $filter: ModelPodFilterInput
                $limit: Int
                $nextToken: String
            ) {
                listPods(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                    id
                    podName
                    domain
                    domainUser
                    timeZone
                    podSubId
                    awsAccountId
                    awsRegion
                    multiTimezone
                    vpcId
                    vpcCidr
                    securityGroupFile
                    securityGroupSql
                    securityGroupMgmt
                    securityGroupAD
                    sqlAddress
                    sqlName
                    sqlAz
                    sqlReportAddress
                    sqlReportName
                    sqlReportAz
                    fileServerAddress
                    fileServerName
                    fileServerAz
                    dnsIpPrimary
                    dnsIpSecondary
                    tgwAttachmentId
                    medFusionCidr
                    instanceKeyPair
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                }
                nextToken
                startedAt
                __typename
                }
            }
        `;

        let variables = {
            filter: {
                _deleted: {
                    ne: true
                }
            }
        };
        try {
            const gq = await Graphql.queryAllResults(listPods, variables);
            console.log("GraphQL response:", gq);  // Log the response here
            const items = gq?.listPods?.items || [];

            setTotalPages(Paginate.getPageCount(pageSize, items.length));
            setVisiblePods(Paginate.getPageItems(items, pageSize));
            return setPods(items);
        } catch (error) {
            console.error("Error fetching pods:", error);
        }
    };

    useEffect(() => {
        fetchPods().then(() => setLoading(false));
    }, []);

    const handleNextPage        = () => {
        setCurrentPage(currentPage + 1);
    };
    const handlePreviousPage    = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleChangePage = (event) => {
        Paginate.setActive(event);

        let newPageIndex    = event.target.innerHTML;

        setVisiblePods(Paginate.getPageItems(pods, pageSize, newPageIndex));
        setCurrentPage(newPageIndex);
    };

    function handleSearch(keywords) {
        let paginationContainer = document.querySelector('.pagination');

        if (keywords.length <= 0) {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'visible';
            }

            return setVisiblePods(pods);
        } else {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'hidden';
            }
        }

        let results = Search.json(keywords, ["podName"], pods);

        return setVisiblePods(results);
    }

    function podsTable() {
        return (
            <table className="lynx-table table table-striped table-hover table-bordered">
                <thead>
                <tr>
                    <th>
                        Pod Name
                    </th>
                    <th>
                        Account ID
                    </th>
                    <th>
                        Region
                    </th>
                    <th>
                        VPC CIDR
                    </th>
                    <th>
                        Multi-timezone?
                    </th>
                </tr>
                </thead>
                <tbody>
                     {visiblePods.map((pod) => (
                        <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/pods/${pod.id}`)} key={pod.id}>
                             <td>{pod.podName}</td>
                             <td>{pod.awsAccountId}</td>
                             <td>{pod.awsRegion ? Utils.formatRegion(pod.awsRegion) : null}</td>
                             <td>{pod.vpcCidr}</td>
                             <td>{pod.multiTimezone ? "Yes" : "No"}</td>
                         </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    function handleSubmitAutomateSqlPodModalDedicated() {
        const form          = document.getElementById("automateSqlPodDedicated");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formDataObj.podType = 'dedicated';

        formData.forEach((value, key) => formDataObj[key] = value);

        if (!formDataObj.App || !formDataObj.Env || !formDataObj.ServerTimeZone || !formDataObj.ActiveDirectoryShort || !formDataObj.PodNumber || !formDataObj.TimeZone) {
            alert('Missing required fields');
            return false;
        }

        console.log(formDataObj);

        return alert('See console.log() output for submitted values.');
    }

    function handleSubmitAutomateSqlPodModalLeveraged() {
        const form          = document.getElementById("automateSqlPodLeveraged");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formDataObj.podType = 'leveraged';

        formData.forEach((value, key) => formDataObj[key] = value);

        if (!formDataObj.App || !formDataObj.Env || !formDataObj.ServerTimeZone || !formDataObj.ActiveDirectoryShort || !formDataObj.PodNumber || !formDataObj.TimeZone) {
            alert('Missing required fields');
            return false;
        }

        console.log(formDataObj);

        return alert('See console.log() output for submitted values.');
    }

    function handleSubmitAddPodModal() {
        const form          = document.getElementById("createPod");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formData.forEach((value, key) => {
            console.log(`Collecting form data - Key: ${key}, Value: ${value}`);
            formDataObj[key] = value;
        });

        if (!formDataObj.podName || !formDataObj.domain || !formDataObj.timeZone || !formDataObj.podSubId) {
            alert('Missing required fields');
            return false;
        }

        formDataObj.sqlAz           = !formDataObj.sqlAz ? null : !formDataObj.sqlAz;
        formDataObj.sqlReportAz     = !formDataObj.sqlReportAz ? null : !formDataObj.sqlReportAz;
        formDataObj.fileServerAz    = !formDataObj.fileServerAz ? null : !formDataObj.fileServerAz;
        formDataObj.awsRegion       = !formDataObj.awsRegion ? null : !formDataObj.awsRegion;

        console.log("Form Data Object:", formDataObj);

        return PodQueries.createPod(formDataObj)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error creating pod:", error);
                alert("Failed to create pod. Please try again.");
            });
    

    }

    return (
        <>
            <h1>
                All Pods
            </h1>

            <hr />
            <div className="mw-100 clearfix">
                <div className="float-start m-2">
                    {Search.getSearchHtml(handleSearch)}
                </div>
                <div className="float-end m-3">
                    <button className="btn btn-success float-end" onClick={openAutomateSqlPodModal}>
                        Automate SQL Pod
                    </button>
                    {Modals.automateSqlPodModal(automateSqlPodModalOpen, closeAutomateSqlPodModal, handleSubmitAutomateSqlPodModalDedicated, handleSubmitAutomateSqlPodModalLeveraged)}
                </div>
                <div className="float-end m-3">
                    <button className="btn btn-success float-end" onClick={openCreatePodModal}>
                        Create Pod
                    </button>
                    {Modals.addPodModal(createPodModalOpen, closeCreatePodModal, handleSubmitAddPodModal)}
                </div>
            </div>
            <div className="mw-100 mt-3 clearfix">
                <ul className="pagination justify-content-center">
                    {Paginate.getPaginationHtml(pageSize, currentPage, pods, handleChangePage)}
                </ul>
            </div>
            <div className="mw-100 clearfix">
                {podsTable()}
                <div className="loading_wrapper">
                    <ScaleLoader loading={loading} />
                </div>
            </div>
        </>
    );
}
