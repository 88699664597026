import {Badge, Tab, Tabs} from "react-bootstrap";

export default function Step9UpdateDatabases() {
    return (
        <>
            <Tabs justify className="mt-3">
                <Tab eventKey="9-1" title={<><Badge className="bg-secondary">9.1</Badge><p>Create Backups</p></>}>
                    Step 9.1 goes here
                </Tab>
                <Tab eventKey="9-2" title={<><Badge className="bg-secondary">9.2</Badge><p>Transfer Backups to S3</p></>}>
                    Step 9.2 goes here
                </Tab>
                <Tab eventKey="9-3" title={<><Badge className="bg-secondary">9.3</Badge><p>Transfer Backups to Destination Server</p></>}>
                    Step 9.3 goes here
                </Tab>
                <Tab eventKey="9-4" title={<><Badge className="bg-secondary">9.4</Badge><p>Restore SQL Backups</p></>}>
                    Step 9.4 goes here
                </Tab>
                <Tab eventKey="9-5" title={<><Badge className="bg-secondary">9.5</Badge><p>Run Post-Migration Script</p></>}>
                    Step 9.5 goes here
                </Tab>
            </Tabs>
        </>
    );
}