

import React, { useState, useCallback } from "react";
import { Badge, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';


export default function Step1MigrationQuestionnaire(clientId) {

    
    
    const navigate = useNavigate();

    const [fileList, setFileList] = useState([]);

    const clientPrefix = `clients/${clientId}/`

    const processFile = ({ file }) => {
        return { file, key: `${clientPrefix}/${file.name}` };
    };

    const onUploadSuccess = ({ file }) => {
        getFileList()
    };

    // Update this function to download files from the public directory
    const downloadFile = useCallback((key, filename) => {
        
        const fileUrl = `${process.env.PUBLIC_URL}/${key}`;

        const element = document.createElement('a');
        element.setAttribute('href', fileUrl);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }, []);

    const onDownloadEmpty = useCallback((event) => {
        downloadFile('docs/MigrationQuestionnaire.docx', `MigrationQuestionnaire_${clientId}.docx`)
    }, [downloadFile, clientId])


    const getFileList = useCallback(() => {
        const fileList = [
            'docs/MigrationQuestionnaire.docx',
            
            // Add more files as needed
        ];
    
        const formattedFileList = fileList.map(file => {
            const filename = file.split('/').pop();
            return {
                lastModified: new Date().toLocaleString(),
                key: file,
                name: filename,
                size: 12345,
            };
        });
    
        setFileList(formattedFileList);
    }, []);
    
    

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setFileList((prevFiles) => [...prevFiles, ...files]);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setFileList((prevFiles) => [...prevFiles, ...files]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

 
    return (
        <>
            <Tabs justify className="mt-3">
                <Tab
                    eventKey="1-1"
                    title={
                        <>
                            <Badge className="bg-secondary">1.1</Badge>
                            <p>Pre-Migration Assessment</p>
                        </>
                    }
                >
                    <div className="step-content">
                        <div className="instructions">
                            <ol>
                                <li>
                                    If a Migration Questionnaire has not yet been started for this customer, click the
                                    "Download Blank Questionnaire" button below.
                                </li>
                                <li>
                                    Once you've gathered the customer's <b>user count</b>, <b>time zone</b>, and whether
                                    they plan on purchasing any <b>additional storage</b>, upload the document below and
                                    capture this info in step 1.2.
                                </li>
                                <li>
                                    Continue uploading revised versions of this file if you need to gather further
                                    information or make any revisions. <b>Note:</b> Clicking download again will pull down
                                    the most recent version of the questionnaire displayed below.
                                </li>
                            </ol>
                        </div>

                        <hr />

                        {/* Drag-and-drop file upload section */}
                        <div
                            className="drop-zone"
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            <img
                                src="/assets/img/file-arrow-up.svg"
                                alt="File upload icon"
                                width="50"
                                height="50"
                            />
                            <p>Drop files here or</p>
                            <label htmlFor="fileInput" className="browse-files">
                                <b>Browse files</b>
                            </label>
                            <input
                                id="fileInput"
                                type="file"
                                accept=".doc,.pdf,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.png,.txt"
                                onChange={handleFileSelect}
                                processFile={processFile}
                                onUploadSuccess={onUploadSuccess}
                                multiple
                                style={{ display: "none" }}
                            />
                        </div>
                        {/* Download button */}
                        <div className="download-btn mt-3">
                            <button onClick={onDownloadEmpty} className="btn btn-primary">
                                Download Blank Questionnaire
                            </button>
                        </div>

                        {/* Display uploaded files */}
                        <div className="uploaded-files mt-3">
                            <table className="lynx-table table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Filename</th>
                                        <th>Last Modified</th>
                                        <th>Size</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fileList.map((file, idx) => (
                                        <tr key={idx} onClick={() => downloadFile(file.key, file.name)}>
                                            <td>{file.name}</td>
                                            <td>
                                                {file.lastModified
                                                    ? new Date(file.lastModified).toLocaleString()
                                                    : "N/A"}
                                            </td>
                                            <td>{(file.size / 1024).toFixed(2)} KB</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Tab>

                <Tab
                    eventKey="1-2"
                    title={
                        <>
                            <Badge className="bg-secondary">1.2</Badge>
                            <p>Assessment Update Client Details</p>
                        </>
                    }
                >
                    <div className="step-content">
                        <ol>
                            <li>
                                Once you've gathered the <b>user count</b>, <b>time zone</b>, and <b>storage</b> requirements from the customer, click the button below and update the appropriate fields on
                                the customer details page.
                            </li>
                            <li>
                                <button
                                    onClick={() => navigate(`/clients/${clientId}`)}
                                    className="btn btn-primary btn-sm"
                                >
                                    Update Client Details
                                </button>
                            </li>
                            <li>
                                Let someone from the IS team know that you've completed this task so that they can begin the build-out.
                            </li>
                        </ol>
                    </div>
                </Tab>
            </Tabs>
        </>
    );
}