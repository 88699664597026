import {Badge, Tab, Tabs} from "react-bootstrap";

export default function Step7DeployFleet() {
    return (
        <>
            <Tabs justify className="mt-3">
                <Tab eventKey="7-1" title={<><Badge className="bg-secondary">7.1</Badge><p>Update Customer Stack</p></>}>
                    Step 7.1 goes here
                </Tab>
            </Tabs>
        </>
    );
}