import AWS from 'aws-sdk';
import { GetSecretValueCommand, SecretsManagerClient } from "@aws-sdk/client-secrets-manager";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

class Utils {
    static async getSecret(key, secretName = null, region = null) {
        const idToken               = localStorage.getItem('idToken');
        const cognitoIdpEndpoint    = 'cognito-idp.' + process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_REGION + '.amazonaws.com/' + process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;

        AWS.config.region           = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_REGION;

        AWS.config.credentials      = await new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
            Logins: {
                [cognitoIdpEndpoint] : idToken
            }
        });

        return await AWS.config.credentials.getPromise().then(async () => {
            secretName = secretName ? secretName : 'lynx-frontend/' + process.env.REACT_APP_LYNX_ENVIRONMENT;

            const client = new SecretsManagerClient({
                region: region ? region : process.env.REACT_APP_AWS_SECRETS_MANAGER_REGION,
                credentials: {
                    accessKeyId: AWS.config.credentials.accessKeyId,
                    secretAccessKey: AWS.config.credentials.secretAccessKey,
                    sessionToken: AWS.config.credentials.sessionToken
                }
            });

            let response;

            try {
                response = await client.send(
                    new GetSecretValueCommand({
                        SecretId: secretName,
                        VersionStage: "AWSCURRENT",
                    })
                );
            } catch (error) {
                return console.error(error);
            }

            const secret = typeof response.SecretString !== "undefined" ? response.SecretString : null;

            return secret ? Utils.getValueFromSecretString(secret, key) : null;
        });
    }

    static getValueFromSecretString(secretString, objKey) {
        try {
            let jsonSecretString = JSON.parse(secretString);

            if (typeof jsonSecretString === "object") {
                return jsonSecretString[objKey];
            }
        } catch (e) {
            return secretString;
        }
    }

    static nl2br(string) {
        return string.toString().replace(/\n/g, "<br>");
    }

    static formatRegion(region) {
        if (!region) return;

        return region.replaceAll('_', '-');
    }

    static formatInternalRegion(region) {
        if (!region) return;

        // Remove 'us' prefix
        let modifiedRegion = region.replace(/^us/, '');

        // Replace underscores with hyphens
        modifiedRegion = modifiedRegion.replace(/_/g, '-');

        // Remove hyphen after region name
        modifiedRegion = modifiedRegion.replace(/-(?=\d)/g, '');

        return modifiedRegion;
    }

    static getSftpEndpointName(region) {
        const env       = process.env.REACT_APP_LYNX_ENVIRONMENT;
        const prefix    = env === 'prod' ? 'sftp' : `sftp-${env}`;

        return `${prefix}-${this.formatRegion(region)}.nextgenmcs.com`;
    }

    static getInternalSftpEndpointName(region) {
        const prefix = 'sftp';

        return `${prefix}${this.formatInternalRegion(region)}-internal.nextgenmcs.com`
    }

    static covertToLongTimeZone(timeZone) {
        const map = {
            "AT": "Alaskan Standard Time",
            "AZ": "US Mountain Standard Time",
            "CT": "Central Standard Time",
            "ET": "Eastern Standard Time",
            "MT": "Mountain Standard Time",
            "PT": "Pacific Standard Time"
        }
        return map[timeZone] || map['ET']
    }

    static getProductName(baseName) {
        const environment = process.env.REACT_APP_ENV
        const prefix = 'lynx-'
        const suffix = environment === 'prd' ? '' : '-test'

        return `${prefix}${baseName}${suffix}`
    }

    static isEmpty(obj) {
        return Object.keys(obj).length === 0
    }

    static timestampToString(timestamp) {
        return new Date(timestamp).toLocaleString() || ""
    }

    static timestampToStringFormatted(timestamp) {
        if (!timestamp) {
            return null;
        }

        let datetime    = new Date(timestamp);

        let year        = datetime.toLocaleString('default', { year: 'numeric' });
        let month       = datetime.toLocaleString('default', { month: '2-digit' });
        let day         = datetime.toLocaleString('default', { day: '2-digit' });
        let time        = datetime.toLocaleTimeString('en-us', { timeZoneName: 'short', hour: '2-digit', minute: '2-digit' });

        return (year + '-' + month + '-' + day + ' ' + time);
    }

    static copyToClipboard(elementId) {
        let copyMe = document.getElementById(elementId);

        copyMe.select();
        copyMe.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(copyMe.value);

        return copyMe.value;
    }


    static async downloadFile(bucket, key) {
            const idToken = localStorage.getItem('idToken');
            const cognitoIdpEndpoint = 'cognito-idp.' + process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_REGION + '.amazonaws.com/' + process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;

            AWS.config.region = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_REGION;

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
                Logins: {
                    [cognitoIdpEndpoint]: idToken
                }
            });

            await AWS.config.credentials.getPromise();

            try {
                const client = new S3Client({
                    region: "us-east-2", // TODO - SPA-2170 - convert to variable
                    credentials: {
                        accessKeyId: AWS.config.credentials.accessKeyId,
                        secretAccessKey: AWS.config.credentials.secretAccessKey,
                        sessionToken: AWS.config.credentials.sessionToken
                    }
                });

                const command = new GetObjectCommand({
                    Bucket: bucket,
                    Key: key,
                });
				
				// added to fix utils.js:189 TypeError: response.blob is not a function onclick 
                // sftp-client.zip in Step 3.1 Setup File Migration Utils 

                const signedUrl = await getSignedUrl(client, command, { expiresIn: 3600 * 8 });
                console.log(signedUrl);

                const response = await fetch(signedUrl);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                return await response.blob();
            } catch (error) {
                console.error(error);
            }
    }


    static getProvisioningProps(userCount) {
        const countIncrement    = 40;
        const maxCounts         = [5, 15, 25];
        const provisioningProps = [];

        // 5, 15, 25 ... 40, 80, 120 ... 4920, 4960, 5000
        for (let i=0; i*countIncrement < 5000; i++) {
            maxCounts.push(countIncrement+(i*countIncrement));
        }

        maxCounts.forEach(maxCount => {
            provisioningProps.push({
                maxCount:           maxCount,
                testServers:        maxCount >= 320 ? 2 : 1,
                termServers:        Math.ceil(userCount/countIncrement),
                ancServers:         maxCount >= 2520 ? 4 : maxCount >= 1520 ? 3 : maxCount >= 760 ? 2 : 1,
                testInstanceType:   maxCount >= 1520 ? 't3a.large' : 't3a.xlarge',
                termInstanceType:   maxCount >= 40 ? 'm6a.2xlarge' : 't3a.xlarge',
                ancInstanceType:    maxCount >= 320 ? 'm6a.2xlarge' : maxCount >= 40 ? 't3a.2xlarge' : 't3a.xlarge',
                loadBalancer:       maxCount >= 80 ? 'Yes' : 'No',
                pxpInstanceType:    maxCount >= 40 ? 'm6a.large' : 't3a.large'
            });
        });

        // Find the correct instance and EBS type based on userCount
        return provisioningProps.find(
            (x) => userCount <= x.maxCount
        );
    }
}

export default Utils;