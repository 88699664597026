import Graphql from "../graphql";

class Queries {
    static updateMigrationRecipes(data) {
        const pushData = async () => {
            const updateMigrationRecipes = `
                 mutation UpdateMigrationRecipes(
                    $input: UpdateMigrationRecipesInput!
                    $condition: ModelMigrationRecipesConditionInput
                ) {
                    updateMigrationRecipes(input: $input, condition: $condition) {
                    id
                    recipeId
                    migrationId
                    recipe {
                        name
                        arn
                        description
                        required
                        id
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                    migration {
                        id
                        dataMigrationRegion
                        migrationName
                        scheduledStart
                        scheduledCutover
                        status
                        useDms
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        clientMigrationsClientId
                        migrationCustomerBaselineAMIId
                        __typename
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                    }
                }
            `;

            let variables = {
                input: data,
            }

            return await Graphql.query(updateMigrationRecipes, variables);
        }

        return pushData();
    };

    static createMigrationRecipes(data) {
        const pushData = async () => {
            const createMigrationRecipes = `
                 mutation CreateMigrationRecipes(
                    $input: CreateMigrationRecipesInput!
                    $condition: ModelMigrationRecipesConditionInput
                ) {
                    createMigrationRecipes(input: $input, condition: $condition) {
                    id
                    recipeId
                    migrationId
                    recipe {
                        name
                        arn
                        description
                        required
                        id
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                    migration {
                        id
                        dataMigrationRegion
                        migrationName
                        scheduledStart
                        scheduledCutover
                        status
                        useDms
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        clientMigrationsClientId
                        migrationCustomerBaselineAMIId
                        __typename
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                    }
                }
            `;

            let variables = {
                input: data,
            }

            return await Graphql.query(createMigrationRecipes, variables);
        }

        return pushData();
    }

}

export default Queries;