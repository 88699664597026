import { useLocation } from "react-router-dom";

export default function Breadcrumbs() {
    let location        = useLocation();
    let currentLink     = '';
    let breadcrumbHtml  = (
        <div className="breadCrumbs"></div>
    );

    const breadcrumbs   = location.pathname
        .split('/')
        .filter(crumb => crumb !== '')
        .map(crumb => {
            currentLink += `/${crumb}`;
            crumb       = crumb.toLowerCase().replace(/[^a-zA-Z0-9]/g, ' ');

            return (
                <div className="breadCrumb" key={crumb}>
                    <a href={currentLink}>{crumb}</a>
                </div>
            );
        });

    breadcrumbs.pop();

    if (location.pathname !== '/') {
        breadcrumbHtml = (
            <div className="breadCrumbs">
                <div className="breadCrumb" key="home">
                    <a href="/">Home</a>
                </div>

                {breadcrumbs}
            </div>
        );
    }

    return breadcrumbHtml;
}