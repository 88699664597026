import { Badge, Button, Tab, Tabs } from "react-bootstrap";
import { useCallback } from "react";
import Utils from "../../../../lib/utils";

export default function Step3MigrateFileshare() {

    const onDownload = useCallback(() => {
        Utils.downloadFile(`lynx-frontend-${process.env.REACT_APP_LYNX_ENVIRONMENT}-assets`, 'sftp-client.zip');
    }, []);

    return (
        <>
            <Tabs justify className="mt-3">
                <Tab eventKey="3-1" title={<><Badge className="bg-secondary">3.1</Badge><p>Setup File Migration Utils</p></>}>
                    <ol>
                        <li>Connect to customer Windows server and validate access to NGROOT share. </li>
                        <li>Copy and unzip <Button onClick={onDownload} variation="primary">sftp-client.zip</Button> to server.</li>
                    </ol>
                </Tab>
                <Tab eventKey="3-2" title={<><Badge className="bg-secondary">3.2</Badge><p>Transfer Files to S3</p></>}>
                    Step 3.2 goes here
                </Tab>
                <Tab eventKey="3-3" title={<><Badge className="bg-secondary">3.3</Badge><p>Transfer Files from S3 to Target File Share</p></>}>
                    Step 3.3 goes here
                </Tab>
            </Tabs>
        </>
    );
}