import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
// import Queries from "./queries";

class Modals {
    static addSnapshotTsModal({
        snapshotModalOpen,
        closeSnapshotModal,
        instanceId,
        description,
        handleDescriptionChange,
        snapshotTerminalServerClick,
    }) {
        return (
            <Modal show={snapshotModalOpen} onHide={closeSnapshotModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Snapshot Instance ${instanceId}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Description:</span>
                    <br></br>
                    <input
                        id="description-input"
                        size="small"
                        style={{
                        width: '300px'
                        }}
                        value={description}
                        onChange={handleDescriptionChange}
                        placeholder="Add description here"
                    />
                    <br />
                    <br />
                    <p>Are you sure you want to create an AMI of this Terminal Server?</p>
                    <div
                        style={{
                        backgroundColor: '#e3f2fd',
                        padding: '10px',
                        borderRadius: '4px',
                        marginTop: '10px',
                        }}
                    >
                        <p>
                            <strong>NOTE</strong>: Snapshot process takes{' '}
                            <strong style={{ color: 'red' }}>25-35 minutes</strong> to complete. You can refresh the page after that.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => snapshotTerminalServerClick()}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => closeSnapshotModal()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    static addReplaceTsModal({
        replaceModalOpen,
        closeReplaceModal,
        instanceId,
        replaceTSClick
    }) {
        return (
            <Modal show={replaceModalOpen} onHide={closeReplaceModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Replace Instance ${instanceId}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to replace this terminal server?</p>
                    <div
                        style={{
                        backgroundColor: '#e3f2fd',
                        padding: '10px',
                        borderRadius: '4px',
                        marginTop: '10px',
                        }}
                    >
                        <p>
                            <strong>NOTE</strong>: Replace instance process takes {' '}
                            <strong style={{ color: 'red' }}>5-6 minutes</strong> to complete. You can refresh the page after that.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => replaceTSClick()}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => closeReplaceModal()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    static renderStepper({ min = 1, max = 21, step = 1, value, onValueChange }) {
            const handleIncrement = () => {
                const newValue = Math.min(value + step, max);
                onValueChange(newValue);
            };

            const handleDecrement = () => {
                const newValue = Math.max(value - step, min);
                onValueChange(newValue);
            };

            return `
                <div style="display: flex; align-items: center; gap: 8px;">
                    <button 
                        type="button" 
                        style="padding: 5px 10px; font-size: 16px; cursor: pointer;"
                        onclick="${handleDecrement}" 
                        ${value <= min ? 'disabled' : ''}
                    >
                        -
                    </button>
                    <input 
                        id="stepper"
                        type="number"
                        min="${min}" 
                        max="${max}" 
                        step="${step}" 
                        value="${value}" 
                        oninput="(e) => onValueChange(e.target.value)"
                        style="width: 60px; text-align: center; padding: 8px; font-size: 14px; border: 1px solid #ccc; border-radius: 4px;"
                    />
                    <button 
                        type="button" 
                        style="padding: 5px 10px; font-size: 16px; cursor: pointer;" 
                        onclick="${handleIncrement}" 
                        ${value >= max ? 'disabled' : ''}
                    >
                        +
                    </button>
                </div>
            `;
    }

    static addStopTsModal({
        stopModalOpen,
        closeStopModal,
        instanceId,
        stopTerminalServer
    }) {
        return (
            <Modal show={stopModalOpen} onHide={closeStopModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Stop Instance ${instanceId}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to stop {instanceId}?</p>
                    <div
                        style={{
                        backgroundColor: '#e3f2fd',
                        padding: '10px',
                        borderRadius: '4px',
                        marginTop: '10px',
                        }}
                    >
                        <p>
                            <strong>NOTE</strong>: Stop instance process takes {' '}
                            <strong style={{ color: 'red' }}>4-10 minutes</strong> to complete. You can refresh the page after that.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => stopTerminalServer()}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => closeStopModal()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }


    static addReplicateTsModal({
        replicateModalOpen,
        closeReplicateModal,
        instanceId,
        stepperValue,
        handleStepperDecrement,
        handleStepperIncrement,
        replicateLaunchButtonClick,
        handleStepInputChange
    }) {
        return (
            <Modal show={replicateModalOpen} onHide={closeReplicateModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Add Terminal Servers from ${instanceId}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>How many additional terminal servers would you like to launch?</p>
                    <div
                        style={{
                        backgroundColor: '#e3f2fd',
                        padding: '10px',
                        borderRadius: '4px',
                        marginTop: '10px',
                        }}
                    >
                        <p>
                            <strong>NOTE</strong>: Replicate instance process takes {' '}
                            <strong style={{ color: 'red' }}>5-6 minutes</strong> to complete. You can refresh the page after that.
                        </p>
                    </div>
                      {/* Custom Stepper with + and - buttons */}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '200px',
                                marginTop: '20px',
                            }}
                        >
                            {/* Decrement Button */}
                            <button
                                onClick={handleStepperDecrement}
                                disabled={stepperValue <= 1} // Disable if value is 1
                                style={{
                                    padding: '8px 12px',
                                    fontSize: '16px',
                                    cursor: stepperValue > 1 ? 'pointer' : 'not-allowed',
                                }}
                            >
                                -
                            </button>

                            {/* Input for the number */}
                            <input
                                id="stepper"
                                type="number"
                                value={stepperValue}
                                onChange={handleStepInputChange}
                                style={{
                                    width: '60px',
                                    textAlign: 'center',
                                    padding: '8px',
                                    fontSize: '16px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    margin: '0 10px'
                                }}
                            />


                            {/* Increment Button */}
                            <button
                                onClick={handleStepperIncrement}
                                disabled={stepperValue >= 21} // Disable if value is 21
                                style={{
                                    padding: '8px 12px',
                                    fontSize: '16px',
                                    cursor: stepperValue < 21 ? 'pointer' : 'not-allowed',
                                }}
                            >
                                +
                            </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => replicateLaunchButtonClick()}>
                        Launch
                    </Button>
                    <Button variant="secondary" onClick={() => closeReplicateModal()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    static cur_packages = [];

    static getFakeData(key)
    {
        return (sessionStorage.getItem(key) !== null) ? JSON.parse(sessionStorage.getItem(key)) : [];
    }
    
    static setFakeData(key, value)
    {
        return sessionStorage.setItem(key, JSON.stringify(value));
    }

    static add_current_inventory(packages, checked){
        console.log(checked)
        console.log(this.cur_packages)
        if(checked){
            this.cur_packages.push({
                key:packages.name,
                    value:packages
            });
        }
        else{
            this.cur_packages.pop(packages.name);
        }
        console.log(this.cur_packages);
    }

    static modalCloseButton(onCloseModal) {
        return (
            <Button onClick={onCloseModal} className={'modal-close-button'}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0 0 30 30">
                    <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
                </svg>
            </Button>
        );
    }


    static showPackages(recipes, buttonComponent, clientId)
    {
        const packages                              = recipes;

        let clientInventoryStorage                  = this.getFakeData('fakeClientInventoryStorage'); // TODO - use listRecipes
        let packageSubmitContainerDefaultDisplay    = null;
        let clientPackageInventory                  = [];
        let packageChangeTally                      = 0;

        if (typeof clientId !== "undefined") {
            clientPackageInventory                  = (typeof clientInventoryStorage[clientId] !== "undefined") ? clientInventoryStorage[clientId].packages : [];
            packageSubmitContainerDefaultDisplay    = 'none';
        }

        let rows = [];

        for (let i = 0; i < packages.length; i++) {
            let asterisk    = packages[i].required === true ? '*' : null;
            let display     = packages[i].required === true ? 'none' : '';
            let isChecked   = false;
            let thumbnail   = !packages[i].thumbnail ? '/assets/img/generic-app-thumbnail.svg' : packages[i].thumbnail;

            for (let j = 0; j < clientPackageInventory.length; j++) {
                if (packages[i].id === clientPackageInventory[j].id) {
                    isChecked = true;
                }
            }

            
            rows.push(
                <tr style={{display:display}} key={`package-${packages[i].id}`}>
                    <td>
                         <input value='' onChange={
                            (e) => {
                                console.log(e);
                                this.add_current_inventory(packages[i], e.target.checked);
                            }
                        } 
                        name="choosePackages[]" type="checkbox" id={packages[i].id} disabled={packages[i].required === true} defaultChecked={(packages[i].required === true) || isChecked} />
                    </td>
                    <td>
                        <img src={thumbnail} style={{width:"25px", minHeight:"25px", verticalAlign: "middle"}}  alt="package-thumbnail"/> &#160;&#160;
                        {packages[i].name}{asterisk}
                    </td>
                    <td>
                        <span style={{textDecoration:"underline"}}>Options&#160;&#187;</span>
                    </td>
                </tr>
            );
        }

        rows.push(
            <tr id="packageSubmitContainer" key="package-buttons">
                <td colSpan={3}>
                    {buttonComponent}&#160;
                    <Button
                        id="reset"
                        onClick={() => {
                            let choosePackages = document.querySelectorAll('input[name="choosePackages[]"]');
                            for (let i = 0; i < choosePackages.length; i++) {
                                choosePackages[i].checked = choosePackages[i].defaultChecked;
                            }
                            this.cur_packages = [];
                            console.log(this.cur_packages);
                        }}
                        size="small"
                        name="reset"
                    >
                        Reset
                    </Button>
                </td>
            </tr>
        );

        return rows;
    }

    static packagesTableComponent(recipes, buttonComponent, clientId) {
        return (
            <table className="lynx-table table table-striped table-hover table-bordered" highlightOnHover={true} variation="striped" style={{border: "3px solid rgb(173, 132, 82)", borderSpacing: 0}}>
                <thead>
                    <tr>
                        <th style={{backgroundColor:"rgb(173, 132, 82)", textAlign:"center",width:"100%"}} colSpan={3}>
                            <span style={{color: "white"}}>
                                Choose Packages
                            </span>
                        </th>
                    </tr>
                    <tr style={{textAlign:"right"}}>
                        <td colSpan={3}>
                            <span style={{fontWeight:"normal"}}>
                                Included *
                            </span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {Modals.showPackages(recipes, buttonComponent, clientId)}
                </tbody>
            </table>
        );
    }
    
    static customStyles() {
        return {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
            },
        };
    }

    static createDescriptionModal({ createDescriptionModalOpen, closeCreateDescriptionModal, buildInventory }) {
        return (
            <Modal 
                style={Modals.customStyles} 
                appElement={document.getElementById('app')} 
                show={createDescriptionModalOpen} 
                onHide={closeCreateDescriptionModal}
            >
                {Modals.modalCloseButton(closeCreateDescriptionModal)}
                <Modal.Header closeButton>
                    <Modal.Title>Create a Description for this Build</Modal.Title>
                </Modal.Header>
                <div style={{ padding: '20px' }}>
                    <p>Enter a brief description for this terminal server for easy reference.</p><br />
                    <p>Description</p>
                    <input 
                        id="tsDescription" 
                        label="Description" 
                        isRequired={true} 
                        placeholder="e.g. TS Scan & Print" 
                        onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} 
                        style={{
                            width: '100%',
                            padding: '10px', 
                            fontSize: '16px',
                            boxSizing: 'border-box',
                            border: '1px solid #ccc',
                            borderRadius: '5px', 
                        }}
                    />
                    <br /><br />
                    <div style={{ backgroundColor: '#e3f2fd', padding: '10px', borderRadius: '5px' }}>
                        <p>
                            <span style={{ fontWeight: 'bold' }}>NOTE</span>: basets process takes about 
                            <span style={{ fontWeight: 'bold', color: "Red" }}> 90 minutes</span> to complete. 
                            You can refresh the page after that.
                        </p>
                    </div>
                    <div style={{ marginTop: '20px', textAlign: 'right' }}>
                        <Button size="small" variation="primary" onClick={buildInventory}>Continue</Button>&#160;
                        <Button size="small" onClick={closeCreateDescriptionModal}>Cancel</Button>
                    </div>
                </div>            
            </Modal>
        );
    }
    

    

}

export default Modals;