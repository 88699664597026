import Modal from "react-bootstrap/Modal";
import { CopyBlock } from "react-code-blocks";
import { Button } from "react-bootstrap";

import Queries from "./queries";

class Modals {
    static deleteMigrationModal(migration, showDeleteMigrationModal, handleCloseDeleteMigrationModal, handleSubmitDeleteMigrationModal) {
        return (
            <Modal show={showDeleteMigrationModal} onHide={handleCloseDeleteMigrationModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirm Deletion
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="fw-bold text-danger">Are you sure you want to delete {migration.migrationName}?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteMigrationModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleSubmitDeleteMigrationModal}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    static viewSftpCredentialsModal(showViewSftpCredentialsModal, handleCloseViewSftpCredentialsModal, sftpUsername, sftpPassword, sftpInternalEndpoint, sftpInternalCodeString, sftpExternalEndpoint, sftpExternalCodeString) {
        return (
            <Modal size="xl" show={showViewSftpCredentialsModal} onHide={handleCloseViewSftpCredentialsModal} backdrop="static">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h5>
                        SFTP External Credentials - Use From Client Self Hosted Servers
                    </h5>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm">
                                <div className="container border border-light-subtle">
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            Username
                                        </div>
                                        <div className="col-sm">
                                            {sftpUsername}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            Password
                                        </div>
                                        <div className="col-sm">
                                            {sftpPassword}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            SFTP Endpoint
                                        </div>
                                        <div className="col-sm">
                                            {sftpExternalEndpoint}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-3">
                                        <CopyBlock customStyle={{overflowX: "auto"}} text={sftpExternalCodeString} showLineNumbers></CopyBlock>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-5" />
                    <h5 className="mt-5">
                        SFTP Internal Credentials - Use From Within Clients NGMCS Hosted Servers
                    </h5>
                    <div className="container mb-5">
                        <div className="row">
                            <div className="col-sm">
                                <div className="container border border-light-subtle">
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            Username
                                        </div>
                                        <div className="col-sm">
                                            {sftpUsername}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            Password
                                        </div>
                                        <div className="col-sm">
                                            {sftpPassword}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-1">
                                        <div className="col fw-bold">
                                            SFTP Endpoint
                                        </div>
                                        <div className="col-sm">
                                            {sftpInternalEndpoint}
                                        </div>
                                    </div>
                                    <div className="row border border-light-subtle p-3">
                                        <CopyBlock customStyle={{overflowX: "auto"}} text={sftpInternalCodeString} showLineNumbers></CopyBlock>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    static selectTargetStackModal(assignableStacks, showSelectTargetStackModal, handleCloseSelectTargetStackModal) {
        return (
            <Modal size="xl" show={showSelectTargetStackModal} onHide={handleCloseSelectTargetStackModal} backdrop="static" className="modal-fullscreen">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Select Target Stack
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="lynx-table table table-striped table-hover table-bordered">
                        <thead>
                        <tr>
                            <th>
                                Stack Name
                            </th>
                            <th>
                                Primary AZ
                            </th>
                            <th>
                                Primary CIDR
                            </th>
                            <th>
                                Secondary AZ
                            </th>
                            <th>
                                Secondary CIDR
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {assignableStacks.map(s => (
                            /*TODO (SPA-2165) - assign stack*/
                            <tr style={{ cursor: 'pointer' }} onClick={() => {Queries.assignStack().then(() => alert('Assign target stack then reload (TODO). See SPA-2165.')) /*window.location.reload()*/}} key={s.id}>
                                <td>
                                    {s.stackName}
                                </td>
                                <td>
                                    {s.az1}
                                </td>
                                <td>
                                    {s.az1Cidr}
                                </td>
                                <td>
                                    {s.az2}
                                </td>
                                <td>
                                    {s.az2Cidr}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        );
    }

    static editDetailsModal(migration, showEditDetailsModal, handleCloseEditDetailsModal, handleSubmitEditDetailsModal) {
        return (
            <Modal show={showEditDetailsModal} onHide={handleCloseEditDetailsModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Migration
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="updateMigration" onSubmit={e => {
                        e.preventDefault();
                    }}>
                        <div className="mb-3">
                            <label>Data Migration Region</label><br/>
                            <select name="dataMigrationRegion" defaultValue={migration.dataMigrationRegion}>
                                <option value="">
                                    Please select an option
                                </option>
                                <option selected={migration.dataMigrationRegion === 'us_east_1' ? 'selected' : false} value="us_east_1">us-east-1</option>
                                <option selected={migration.dataMigrationRegion === 'us_east_2' ? 'selected' : false} value="us_east_2">us-east-2</option>
                                <option selected={migration.dataMigrationRegion === 'us_west_2' ? 'selected' : false} value="us_west_2">us-west-2</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Migration Name *</label><br/>
                            <input type="text" name="migrationName" required="required" defaultValue={migration.migrationName} />
                        </div>
                        <div className="mb-3">
                            <label>Estimated Start</label><br/>
                            <input type="date" name="scheduledStart" defaultValue={migration.scheduledStart} />
                        </div>
                        <div className="mb-3">
                            <label>Estimated Cutover</label><br/>
                            <input type="date" name="scheduledCutover" defaultValue={migration.scheduledCutover} />
                        </div>
                        <div className="mb-3">
                            <label>Status</label><br/>
                            <select name="status" defaultValue={migration.status}>
                                <option value="">Please select an option</option>
                                <option selected={migration.status === 'NotStarted' ? 'selected' : false} value="NotStarted">Not started</option>
                                <option selected={migration.status === 'InProgress' ? 'selected' : false} value="InProgress">In progress</option>
                                <option selected={migration.status === 'OnHold' ? 'selected' : false} value="OnHold">On hold</option>
                                <option selected={migration.status === 'Blocked' ? 'selected' : false} value="Blocked">Blocked</option>
                                <option selected={migration.status === 'Complete' ? 'selected' : false} value="Complete">Complete</option>
                                <option selected={migration.status === 'NotApplicable' ? 'selected' : false} value="NotApplicable">Not applicable</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Use Database Migration Service (DMS)</label><br/>
                            <div className="form-check form-switch">
                                <input type="checkbox" name="useDms" className="form-check-input" defaultChecked={migration.useDms} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditDetailsModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitEditDetailsModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Modals;